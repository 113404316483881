<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form :inline="true">
          <el-form-item label='关键字:'>
            <el-input placeholder="输入号码库" v-model='filter.phones' clearable @clear='clearContent'></el-input>
          </el-form-item>
          <el-form-item>
              <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
     <!-- 表格主体 -->
    <el-table
        v-loading='listLoading'
        :data="tableData"
        :cell-style="rowClass"
        :header-cell-style='headerClass'
        border
        highlight-current-row
        style="width: 95%"
        @current-change='currentChange'>
        <el-table-column type='index' label='序号' width='60'></el-table-column>
        <el-table-column prop="Name" label="号码库名称"></el-table-column>
        <el-table-column prop="Remarks" label="描述"></el-table-column>
        <el-table-column prop="PhoneCount" label="总数"></el-table-column>
        <el-table-column prop="CreateTime" label="创建时间" :formatter="formatCreateTime"></el-table-column>
        <el-table-column prop="CreateUserName" label="创建人"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.PageIndex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pages.PageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pages.DataCount"
      class='pageBar'>
    </el-pagination>
    <!-- 新增/编辑 -->
    <el-dialog
        :title="dialogName"
        :visible.sync="dialogVisible"
        :close-on-click-modal='false'
        @close='dialogClose'
        width="50%">
        <el-form :model="dialogForm" :rules="dialogFormRules" ref="dialogFormRef" label-width="100px" enctype="multipart/form-data">
            <el-form-item label='号码库名称:' prop='name'>
                <el-input v-model='dialogForm.name'></el-input>
            </el-form-item>
            <el-form-item label='描述:' prop='remarks'>
                <el-input v-model='dialogForm.remarks'></el-input>
            </el-form-item>
            <el-form-item label='号码文件:' class='phoneFile'>
                <template>
                    <input v-if="viewsFileChi" type="file" id='file' name='file' accept='#' required='required' ref='file' mustwrite="true">
                </template>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="formSubmit" :loading="addLoading">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import util from "../../../util/date";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs'
import { getPhoneNumberLibraryListPage,addPhoneNumberLibrary1,updatePhoneNumberLibrary1,deletePhoneNumberLibrary } from '@/api/api'
import api from '@/api'
export default {
    components:{Toolbar},
    data(){
        return {
            filter:{
                phones:''
            },
            buttonList:[],
            tableData:[],
            currentRow:null,
            // 对话框的显示与隐藏
            dialogVisible:false,
            addLoading:false,
            dialogName:'',
            dialogForm:{
                name:'',
                remarks:'',
                id: '',
            },
            dialogFormRules:{
                name:[{required:true,message:'请输入号码库名称',trigger:'blur'}],
                remarks:[{required:true,message:'请输入描述',trigger:'blur'}]
            },
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            viewsFileChi:true,
            listLoading:false
        }
    },
    methods:{
        // 列表样式
        headerClass(){
            return 'text-align:center;'
        },
        rowClass({columnIndex}){
            if(columnIndex == 2){
                return 'color:red;text-align:center;'
            }
            return 'padding:8px 0!important;text-align:center;'
        },
        // 表单提交
        formSubmit(){
            if(this.dialogName == '新增号码库'){
                var fileValue = document.getElementById('file').value
                this.$refs['dialogFormRef'].validate(valid => {
                    if(valid){
                        if(fileValue.length == 0){
                            this.$message({
                                message:'请选择上传文件',
                                type:'warning'
                            })
                        }else{                          
                            var formData = new FormData()
                            let file = document.getElementById('file')
                            formData.append('file',file.files[0])
                            formData.append('Name',this.dialogForm.name)
                            formData.append('Remarks',this.dialogForm.remarks)
                            this.addLoading = true
                            axios({
                                method: 'post',
                                headers:{
                                    'Content-Type':'multipart/form-data',
                                    'Authorization':localStorage.getItem("Token")
                                },
                                url: `${addPhoneNumberLibrary1}`,
                                data: formData
                            }).then(res => {
                                if(res.data.Success){
                                    this.$message({
                                        message:res.data.Message,
                                        type:'success'
                                    })
                                    this.dialogVisible = false
                                    this.addLoading = false
                                    this.dialogClose()
                                    this.getData()
                                }else{
                                    this.$message({
                                        message:res.data.Message,
                                        type:'error'
                                    })
                                    this.dialogForm = {}
                                    this.addLoading = false
                                }
                            })               
                        }                      
                    }else{
                        return false
                    }
                })
            }
            else if(this.dialogName == '编辑号码库'){ 
                var fileValue = document.getElementById('file').value             
                this.$refs['dialogFormRef'].validate(valid => {
                    if(valid){
                        var txtFile = this.$refs['file'].value 
                        var formData = new FormData()
                        let file = document.getElementById('file')
                        if(txtFile == null){
                            formData.set('file',null)
                        }else{
                            formData.append('file',file.files[0])
                            formData.append('Name',this.dialogForm.name)
                            formData.append('Remarks',this.dialogForm.remarks)
                            formData.append('ID',this.currentRow.ID)
                        }  
                        this.addLoading = true                
                        axios({
                            method: 'post',
                            headers:{
                                'Content-Type':'multipart/form-data',
                                'Authorization':localStorage.getItem("Token")
                            },
                            url: `${updatePhoneNumberLibrary1}`,
                            data: formData
                        }).then(res => {
                            if(res.data.Success){
                                this.$message({
                                    message:res.data.Message,
                                    type:'success'
                                })
                                this.dialogVisible = false
                                this.addLoading = false
                                this.dialogClose()
                                this.getData()
                            }else{
                                this.$message({
                                    message:res.data.Message,
                                    type:'error'
                                })
                                this.dialogForm = {}
                                this.viewsFileChi = false;
                                this.addLoading = false;
                                this.$nextTick(() => {
                                    this.viewsFileChi = true;
                                });
                            }
                        })
                    }
                })
            }
        },
        getToken() {
            return localStorage.getItem("Token")
        },
        // 获取当前行数据
        currentChange(row){
            this.currentRow = JSON.parse(JSON.stringify(row))
        },
        // 查询
        getPhoneNumberLibraryListPage(){
            this.getData()
        },
        // 新增号码库
        handleAdd(){
            this.dialogVisible = true
            this.dialogName = '新增号码库'
            this.dialogForm = {
                name:'',
                remarks:'',
                id: '',
            }
            this.viewsFileChi = false;
            this.$nextTick(() => {
                this.viewsFileChi = true;
            });
        },
        // 编辑
        handleEdit(){
            var row = this.currentRow
            if(row == null){
                this.$message({
                    message:'请选择编辑的一行数据',
                    type:'error'
                })
            }else{                
                this.dialogVisible = true
                this.dialogName = '编辑号码库'
                this.dialogForm.name = this.currentRow.Name
                this.dialogForm.remarks = this.currentRow.Remarks
                this.dialogForm.id = this.currentRow.ID
                this.viewsFileChi = false;
                this.$nextTick(() => {
                    this.viewsFileChi = true;
                });
            }
        },
        // 删除
        handleDel(){
            var row = this.currentRow
            if(row == null){
                this.$message({
                    message:'请选择删除的一行数据',
                    type:'error'
                })
            }else{
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deletePhoneNumberLibrary({ID:this.currentRow.ID}).then(res => {
                        if(res.data.Success){
                            this.$message({
                                message:res.data.Message,
                                type:'success'
                            })
                            this.getData()
                        }else{
                            this.$message({
                                message:res.data.Message,
                                type:'error'
                            })
                        }
                    }).catch(() => {})
                }).catch(() => {})
                
            }
        },
        // 查询详情
        getPhoneNumberLibraryDetailListPage(){
            var row = this.currentRow
            if(row == null){
                this.$message({
                message:'请选择一行数据！',
                type:'warning'
                })
            }else{
                this.$router.replace({path:'/EnterPhoneDetail',query:{id:row.ID}})
            }
        },
        // 清空表单内容
        dialogClose(){
            this.$refs['dialogFormRef'].resetFields()
        },
        // 筛选内容清空，重新获取数据
        clearContent(){
            this.getData()
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        // 获取页面数据
        getData(){
            this.listLoading = true
            var params = {
                name:this.filter.phones,
                isEnable:'',
                organizationInfoID:'',
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize
            }
            getPhoneNumberLibraryListPage(params).then(res => {
                if(res.data.Success){
                    this.listLoading = false
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                }else{
                    this.listLoading = false
                    this.$message.error(res.data.Message)
                }
            })
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    created(){
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }

    .phoneFile::before{
        position: relative;
        content: '*';
        color: #f56c6c;
        margin-right: 4px;
        float: left;
        margin-left: -10px;
        left: 30px;
        top: 10px;
    }
</style>